<template>
  <span class="position-relative">
    <img class="svg-sub4 icon-info unselect" :src="`/static/icon/${icon}.svg`"
         :style="{width:iconSize+'px',height:iconSize+'px'}"
         @mouseover="vTooltip=true"
         @mouseleave="vTooltip=false"/>
    <div class="box-tooltip" v-if="vTooltip"
         :style="toolTipStyle">
      <div class="body6-medium" style="white-space:pre-line">{{ text }}</div>
      <div class="triangle"></div>
    </div>
  </span>
</template>

<script>
  export default {
    name: "ToolTip",
    props: {
      icon: {
        type: String,
        default: 'info'
      },
      text: {
        type: String
      },
      iconSize: {
        type: Number,
        default: 24
      }
    },
    created() {

    },
    computed: {
      toolTipStyle() {
        let innerWidth = window.innerWidth;
        let width = innerWidth < 1000 ? 300 : this.text.length*11;
        return {
          width: `${width}px`,
          left: `-${width/2 - 9}px`,
          top: `-${innerWidth < 1000 && this.text.length > 30 ? 60 : 46}px`
        }
      },
    },
    data() {
      return {
        vTooltip: false
      }
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .icon-info
    color $sub5
    vertical-align text-bottom

  .box-tooltip
    background-color $main
    color white
    position absolute
    padding 8px 12px
    border-radius 4px
    text-align center
  .triangle
    position absolute
    left 50%
    bottom -9px
    width 10px
    height 10px
    background-color $main
    transform rotate(45deg) translateX(-50%)
</style>
